import type { Ref, ComputedRef } from 'vue'
import {
  SB_PENALTY_STATUSES_BY_SPORT,
  SB_OVERTIME_STATUSES,
  SPORT_IDS,
  CYBER_SPORT_ID,
  PENALTY_STATUS,
  MOBA_CYBER_GAMES_CATEGORIES,
} from 'markets-store/constants'
import type {
  TQualifier,
  TMatch,
  TMatchStats,
  TPeriodScore,
  TSportEvent,
} from 'markets-store'
import { formatCricketStatsForScoreboard } from '../../helpers'

export type TExtendedPeriodScore = Omit<
  TPeriodScore,
  'home_score' | 'away_score' | 'match_status_code' | 'number'
> & {
  home_score?: number | string
  home_kills?: number | string
  away_score?: number | string
  away_kills?: number | string
  dataLabel?: number | string
  dataIcon?: string
  match_status_code?: number
  number?: number
  higher?: TQualifier
  home_total_score?: number
  away_total_score?: number
}

const hockeyIds = [
  SPORT_IDS.BALL_HOCKEY,
  SPORT_IDS.ICE_HOCKEY,
  SPORT_IDS.FIELD_HOCKEY,
]
const soccerIds = [
  SPORT_IDS.SOCCER_BEACH,
  SPORT_IDS.FOOTSAL,
  SPORT_IDS.RUGBY,
  SPORT_IDS.KABADDI,
  SPORT_IDS.SOCCER,
]
const volleyballIds = [
  SPORT_IDS.VOLLEYBALL_BEACH,
  SPORT_IDS.BOWLES,
  SPORT_IDS.SQUASH,
  SPORT_IDS.VOLLEYBALL,
]
const basketballIds = [
  SPORT_IDS.AUSTRALIAN_FOOTBALL,
  SPORT_IDS.BASKETBALL,
  SPORT_IDS.BASKETBALL_STREET,
  SPORT_IDS.AMERICAN_FOOTBALL,
  SPORT_IDS.WATERPOLO,
]

const overtimeStatuses = Object.values(SB_OVERTIME_STATUSES) as number[]

function useDefaultPeriodScores(
  periodScores: TPeriodScore[],
  i18n: ReturnType<typeof useI18n>,
  sportId: number,
  isMini: boolean,
): TExtendedPeriodScore[] {
  function getPeriodName(period: TPeriodScore) {
    const code = period.match_status_code

    const hockeyPenaltyStatuses =
      SB_PENALTY_STATUSES_BY_SPORT[SPORT_IDS.ICE_HOCKEY]

    if (code === SB_OVERTIME_STATUSES.FIRST_OVERTIME) {
      return i18n.t('scoreboard.firstOvertime')
    }

    if (code === SB_OVERTIME_STATUSES.SECOND_OVERTIME) {
      return i18n.t('scoreboard.secondOvertime')
    }

    if (overtimeStatuses.includes(code))
      return i18n.t('scoreboard.overTimeShort')

    if (
      [...hockeyIds].includes(sportId) &&
      hockeyPenaltyStatuses.includes(code)
    ) {
      return i18n.t('scoreboard.pennaltiesIce')
    }

    if (sportId === SPORT_IDS.SNOOKER) {
      return isMini
        ? `${period.number}${i18n.t('scoreboard.snookerPeriod')}`
        : `${period.number}`
    }

    if (sportId === SPORT_IDS.HANDBALL) {
      return isMini
        ? `${period.number}${i18n.t('scoreboard.halfShort')}`
        : `${period.number}`
    }

    if (soccerIds.includes(sportId)) {
      if (code === PENALTY_STATUS) {
        return i18n.t('scoreboard.penaltyShort')
      }

      return isMini
        ? `${period.number}${i18n.t('scoreboard.halfShort')}`
        : `${period.number}`
    }

    if (sportId === CYBER_SPORT_ID) {
      return `${period.number}${i18n.t('scoreboard.mapShort')}`
    }

    if (sportId === SPORT_IDS.DARTS) {
      return `${period.number}${i18n.t('scoreboard.setShort')}`
    }

    return isMini
      ? `${period.number}${i18n.t('scoreboard.suffixes.period')}`
      : `${period.number}`
  }

  return periodScores.map((period) => ({
    ...period,
    dataLabel: getPeriodName(period),
  }))
}

function useLastPeriodScores(
  sportId: number,
  periodScores: TPeriodScore[],
): TExtendedPeriodScore[] {
  if (!periodScores.length) return []

  const getDataLabel = (id: number, period: TPeriodScore) => {
    if (id === SPORT_IDS.BASEBALL) return `${period.number}И`
    if (id === SPORT_IDS.DARTS) return `${period.number}С`
    return period.number
  }

  const lastPeriod = periodScores.at(-1)!

  return [
    {
      ...lastPeriod,
      dataLabel: getDataLabel(sportId, lastPeriod),
    },
  ]
}

function usePeriodWithHigherScores(
  sportId: number,
  periodScores: TPeriodScore[],
  i18n: ReturnType<typeof useI18n>,
  isMini: boolean,
): TExtendedPeriodScore[] {
  const getDataLabel = (id: number, period: TPeriodScore) => {
    if (basketballIds.includes(id)) {
      return period.match_status_code === SB_OVERTIME_STATUSES.OVERTIME
        ? i18n.t('scoreboard.overTimeShort')
        : `${period.number}Ч`
    }

    if (
      [...volleyballIds, SPORT_IDS.TABLE_TENNIS, SPORT_IDS.BADMINTON].includes(
        id,
      )
    )
      return isMini
        ? `${period.number}${i18n.t('scoreboard.setShort')}`
        : `${period.number}`

    return period.number.toString()
  }

  const scoreHigher = (period: TPeriodScore) => {
    if (period.away_score === period.home_score) return undefined
    return period.away_score > period.home_score ? 'away' : 'home'
  }

  return periodScores.map((period) => ({
    ...period,
    higher: scoreHigher(period),
    dataLabel: getDataLabel(sportId, period),
  }))
}

function useCricketPeriodScores(
  stats: TMatchStats | undefined,
  provider: number,
  status: number | undefined,
  isMini: boolean,
): TExtendedPeriodScore[] {
  const cricketStats = formatCricketStatsForScoreboard(
    { ...stats, match_status_code: status },
    {
      isShownOverAndDelivery: !isMini,
      eventProvider: provider,
    },
  )
  const lastPeriod = cricketStats.periodScores.at(-1)

  return [
    {
      home_score: lastPeriod?.scores.home,
      away_score: lastPeriod?.scores.away,
      home_total_score: cricketStats.totalScores.home,
      away_total_score: cricketStats.totalScores.away,
      dataLabel: lastPeriod?.number,
    },
  ]
}

function useTennisPeriodScores(
  periodScores: TPeriodScore[],
  match: TMatch | undefined,
  i18n: ReturnType<typeof useI18n>,
  isMini: boolean,
): TExtendedPeriodScore[] {
  const homeGamescore = match?.stats?.home_gamescore || 0
  const awayGamescore = match?.stats?.away_gamescore || 0

  if (isMini) {
    const lastPeriod = periodScores.at(-1)

    const result: TExtendedPeriodScore[] = [
      {
        home_score: homeGamescore === 50 ? 'A' : homeGamescore,
        away_score: awayGamescore === 50 ? 'A' : awayGamescore,
        dataLabel: i18n.t('scoreboard.tennisO'),
      },
    ]

    if (lastPeriod) {
      result.unshift({
        ...lastPeriod,
        dataLabel: i18n.t('scoreboard.gameShort'),
      } as TExtendedPeriodScore)
    }

    return result
  }

  const scores = periodScores.map((period) => ({
    ...period,
    dataLabel: `${period.number} ${i18n.t('scoreboard.suffixes.set')}`,
  })) as TExtendedPeriodScore[]

  const currentPeriod = periodScores.at(-1) ?? ({} as TPeriodScore)

  const { home_score: setHomeScore = 0, away_score: setAwayScore = 0 } =
    currentPeriod
  const currentGameNumber = setHomeScore + setAwayScore + 1

  const game: TExtendedPeriodScore = {
    home_score: homeGamescore === 50 ? 'A' : homeGamescore,
    away_score: awayGamescore === 50 ? 'A' : awayGamescore,
    dataLabel: `${currentGameNumber} ${i18n.t('scoreboard.game')}`,
  }

  scores.push(game)

  return scores
}

function useCyberSportMobaPeriodScores(
  event: Ref<TSportEvent>,
  i18n: ReturnType<typeof useI18n>,
) {
  const periodScores = event.value.match?.stats?.period_scores || []

  return periodScores.map((period) => ({
    ...period,
    home_score: period.home_kills ?? 0,
    away_score: period.away_kills ?? 0,
    dataLabel: `${period.number}${i18n.t('scoreboard.mapShort')}`,
  }))
}

export function useSoccerStatsScores(event: Ref<TSportEvent>) {
  const redCards = computed(() => ({
    home_score:
      (event.value.match?.stats?.statistics?.red_cards.home || 0) +
      (event.value.match?.stats?.statistics?.yellow_red_cards?.home || 0),
    away_score:
      (event.value.match?.stats?.statistics?.red_cards.away || 0) +
      (event.value.match?.stats?.statistics?.yellow_red_cards?.away || 0),
  }))

  const yellowCrads = computed(() => ({
    home_score: event.value.match?.stats?.statistics?.yellow_cards.home,
    away_score: event.value.match?.stats?.statistics?.yellow_cards.away,
  }))

  const corners = computed(() => ({
    home_score: event.value.match?.stats?.statistics?.corners?.home || 0,
    away_score: event.value.match?.stats?.statistics?.corners?.away || 0,
  }))

  return {
    redCards,
    yellowCrads,
    corners,
  }
}

export function usePeriodScores(
  event: Ref<TSportEvent>,
  isMini = true,
): ComputedRef<TExtendedPeriodScore[]> {
  const i18n = useI18n()

  return computed(() => {
    if (event.value.sportId === SPORT_IDS.BASEBALL) {
      return useLastPeriodScores(
        event.value.sportId,
        event.value?.match?.stats?.period_scores || [],
      )
    }

    if (soccerIds.includes(event.value.sportId)) {
      const scores = useDefaultPeriodScores(
        event.value?.match?.stats?.period_scores || [],
        i18n,
        event.value.sportId,
        isMini,
      )

      if (
        [SPORT_IDS.SOCCER, SPORT_IDS.FOOTSAL].includes(event.value.sportId) &&
        !isMini
      ) {
        const { redCards, yellowCrads, corners } = useSoccerStatsScores(event)

        scores.unshift({ ...corners.value, dataIcon: 'corner' })
        scores.unshift({ ...yellowCrads.value, dataIcon: 'yellowCard' })
        scores.unshift({ ...redCards.value, dataIcon: 'redCard' })
      }

      return scores
    }

    if (
      [
        ...basketballIds,
        ...volleyballIds,
        SPORT_IDS.BADMINTON,
        SPORT_IDS.TABLE_TENNIS,
      ].includes(event.value.sportId)
    ) {
      return usePeriodWithHigherScores(
        event.value.sportId,
        event.value?.match?.stats?.period_scores || [],
        i18n,
        isMini,
      )
    }

    if (
      CYBER_SPORT_ID === event.value.sportId &&
      MOBA_CYBER_GAMES_CATEGORIES.includes(event.value.categoryId)
    ) {
      return useCyberSportMobaPeriodScores(event, i18n)
    }

    if (
      [
        ...hockeyIds,
        SPORT_IDS.HANDBALL,
        SPORT_IDS.SNOOKER,
        CYBER_SPORT_ID,
      ].includes(event.value.sportId)
    ) {
      return useDefaultPeriodScores(
        event.value?.match?.stats?.period_scores || [],
        i18n,
        event.value.sportId,
        isMini,
      )
    }

    if (event.value.sportId === SPORT_IDS.CRICKET) {
      return useCricketPeriodScores(
        event.value.match?.stats,
        event.value.provider,
        event.value.match?.status,
        isMini,
      )
    }

    if (event.value.sportId === SPORT_IDS.TENNIS) {
      return useTennisPeriodScores(
        event.value?.match?.stats?.period_scores || [],
        event.value.match,
        i18n,
        isMini,
      )
    }

    if (event.value.sportId === SPORT_IDS.DARTS) {
      return isMini
        ? useLastPeriodScores(
            event.value.sportId,
            event.value?.match?.stats?.period_scores || [],
          )
        : useDefaultPeriodScores(
            event.value?.match?.stats?.period_scores || [],
            i18n,
            event.value.sportId,
            isMini,
          )
    }

    return event.value.match?.stats?.period_scores || []
  })
}
